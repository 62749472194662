import React, { useState } from 'react';
import './CreateMerchantOffer.scss';
import { FlightButton, getIcon } from '@flybits/design-system';
import FlybitsLogo from 'assets/images/flybits-logo.svg';
import Breadcrumbs from 'components/Shared/Breadcrumbs/Breadcrumbs';
import OfferForm from 'components/MerchantPortal/OfferForm/OfferForm';
import { OfferFormValues, OfferScheduleFormValues } from '../merchant-portal.types';
import { ReactComponent as EditOffer } from 'assets/icons/edit_offer.svg';
import { ReactComponent as Schedule } from 'assets/icons/schedule.svg';
import OfferScheduleForm from 'components/MerchantPortal/OfferForm/OfferScheduleForm';

const CREATE_MERCHANT_OFFER = 'create-merchant-offer';
const CLASSES = {
  HEADER: `${CREATE_MERCHANT_OFFER}__header`,
  INFO: `${CREATE_MERCHANT_OFFER}__header__info`,
  TITLE: `${CREATE_MERCHANT_OFFER}__header__title`,
  BUTTON: `${CREATE_MERCHANT_OFFER}__header__button`,
  SUBTITLE: `${CREATE_MERCHANT_OFFER}__header__subtitle`,
  BODY: `${CREATE_MERCHANT_OFFER}__body`,
  BREADCRUMBS: `${CREATE_MERCHANT_OFFER}__body__breadcrumbs`,
  CONTAINER: `${CREATE_MERCHANT_OFFER}__body__container`,
};
// NOTE: These are EXAMPLE icons. Please update to match with designs when implementing
const BREADCRUMB_ITEMS = [
  { icon: <EditOffer />, forceOutline: true, text: 'Enter offer information' },
  { icon: <Schedule />, forceOutline: true, text: 'Enter schedule and budget' },
  { icon: <>{getIcon('eyeOpenOutline', {})}</>, forceOutline: true, text: 'Review and confirm' },
];

const initialValues: OfferFormValues = {
  type: 'cashback',
  merchantName: 'Yellow Bird Cafe',
  limit: '',
  redemptionLimit: null,
  percentage: 1,
  spendThreshold: 0,
  earnCap: 0,
  notes: '',
  title: '',
  description: '',
};
const today = new Date();
const todayEpoch = (today.getTime() - today.getMilliseconds()) / 1000;
const initialScheduleValues: OfferScheduleFormValues = {
  startDate: todayEpoch,
  endDate: todayEpoch + 86359, //86359 for 1 day in epoch
  targetBudget: 0,
  budgetNote: '',
};
const CreateMerchantOffer = function () {
  const [selectedStep, setSelectedStep] = useState(1);
  const [offerData, setOfferData] = useState<OfferFormValues>(initialValues);
  const [offerScheduleData, setOfferScheduleData] = useState<OfferScheduleFormValues>(initialScheduleValues);

  return (
    <div className={CREATE_MERCHANT_OFFER}>
      <header className={CLASSES.HEADER}>
        <img alt="flybits logo" src={FlybitsLogo} />
        <div className={CLASSES.INFO}>
          <div className={CLASSES.TITLE}>Submit a New Merchant Offer</div>
          <div className={CLASSES.SUBTITLE}>
            Use this form to submit draft copy and offer details for a new merchant offer
          </div>
        </div>
        <FlightButton theme="secondary" label="Open Content Editor" className={CLASSES.BUTTON} onClick={() => null} />
      </header>
      <div className={CLASSES.BODY}>
        <section className={CLASSES.BREADCRUMBS}>
          <Breadcrumbs items={BREADCRUMB_ITEMS} selectedStep={selectedStep} />
        </section>
        {selectedStep === 1 && (
          <section className={CLASSES.CONTAINER}>
            <OfferForm
              onSubmit={(values: OfferFormValues) => {
                setOfferData(values);
                setSelectedStep(2);
              }}
              data={offerData}
            />
          </section>
        )}
        {selectedStep === 2 && (
          <section className={CLASSES.CONTAINER}>
            <OfferScheduleForm
              onSubmit={(values: OfferScheduleFormValues) => {
                setOfferScheduleData(values);
                setSelectedStep(3);
              }}
              onPrevious={() => setSelectedStep(1)}
              data={offerScheduleData}
            />
          </section>
        )}
        {selectedStep === 3 && <section className={CLASSES.CONTAINER}>review and confirm</section>}
      </div>
    </div>
  );
};

export default CreateMerchantOffer;

import React from 'react';
import { FlightButton, FlightDatePicker, FlightTextArea, FlightTextInput } from '@flybits/design-system';
import './OfferForm.scss';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
// import ScrollToFormikErrorField from 'components/Shared/ScrollToFormikErrorField/ScrollToFormikErrorField';
import { OfferScheduleFormValues } from 'pages/MerchantPortal/merchant-portal.types';
import { dateToEpoch, formatCurrency, validateInput } from 'pages/MerchantPortal/merchant-portal.helpers';

const OFFER_FORM = 'offer-form';
const CLASSES = {
  CONTENT: `${OFFER_FORM}__content`,
  CONTENT_LEFT: `${OFFER_FORM}__content__left`,
  CONTENT_RIGHT: `${OFFER_FORM}__content__right`,
  CONTENT_FOOTER: `${OFFER_FORM}__content__footer`,
  CONTENT_MONEY: `${OFFER_FORM}__content__money`,
};

interface FormProps {
  onPrevious: () => void;
  onSubmit: (values: OfferScheduleFormValues) => void;
  data: OfferScheduleFormValues;
}

const validationSchema = Yup.object().shape({
  startDate: Yup.number().required('Start date cannot be empty'),
  endDate: Yup.number()
    .required('End date cannot be empty')
    .test('dates-test', 'End date should be after start date', (value, context) => {
      return value > context.parent.startDate;
    }),
  targetBudget: Yup.string().required('Target budget cannot be empty'),
});

function OfferScheduleForm(props: FormProps) {
  const { onSubmit, onPrevious, data } = props;
  const handleClickPrevious = () => {
    onPrevious();
  };
  return (
    <Formik
      initialValues={data}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
    >
      {function DisplayForm({ values, errors, isValid, handleChange, handleBlur, handleSubmit, setFieldValue }) {
        return (
          <Form className={OFFER_FORM} autoComplete="off">
            <header>
              <h2>Offer Schedule & Budget</h2>
              <p>Please provide details about when the offer should be live and its expected budget</p>
            </header>
            <main className={CLASSES.CONTENT}>
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>Expected launch schedule</h3>
                  <p>
                    Please provide the expected launch dates as provided by the merchant. Please note that exact launch
                    dates may be adjusted
                  </p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="startDate">
                    Desired start date<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="startDate"
                    width="100%"
                    value={values.startDate}
                    as={FlightDatePicker}
                    onChange={handleChange}
                    selected={new Date(values.startDate * 1000)}
                    onSelect={(newDate: Date) => {
                      setFieldValue('startDate', dateToEpoch(newDate));
                    }}
                    onBlur={handleBlur}
                    hasError={!!errors.startDate}
                    errorMessage={
                      <>
                        <b>Error:</b> {errors.startDate}
                      </>
                    }
                  />
                  <span>Select the desired start date for the offer</span>
                  <label htmlFor="endDate">
                    End date<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="endDate"
                    width="100%"
                    value={values.endDate}
                    as={FlightDatePicker}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    selected={new Date(values.endDate * 1000)}
                    onSelect={(newDate: Date) => {
                      setFieldValue('endDate', dateToEpoch(newDate));
                    }}
                    hasError={!!errors.endDate}
                    errorMessage={
                      <>
                        <b>Error:</b> {errors.endDate}
                      </>
                    }
                  />
                  <span>Select the end date for the offer</span>
                </div>
              </section>
              <hr />
              <section>
                <div className={CLASSES.CONTENT_LEFT}>
                  <h3>Offer budget</h3>
                  <p>
                    Please provide the budget for the offer as indicated by the merchant. Feel free to include any
                    additional notes or instructions about budget
                  </p>
                </div>
                <div className={CLASSES.CONTENT_RIGHT}>
                  <label htmlFor="title">
                    Target budget<span>*</span>
                  </label>
                  <Field
                    type="text"
                    name="targetBudget"
                    width="100%"
                    value={values.targetBudget}
                    as={FlightTextInput}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      formatCurrency(e, setFieldValue, 'targetBudget')
                    }
                    onKeyDown={validateInput}
                    onBlur={handleBlur}
                    hasError={!!errors.targetBudget}
                    errorMessage={
                      <>
                        <b>Error:</b> {errors.targetBudget}
                      </>
                    }
                  />
                  <span>Enter the target budget amount for this offer</span>
                  <label htmlFor="budgetNote">Budget note</label>
                  <Field
                    type="text"
                    name="budgetNote"
                    label=""
                    width="100%"
                    value={values.budgetNote}
                    as={FlightTextArea}
                    hideCharCounter
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!errors.budgetNote}
                    errorMessage={
                      <>
                        <b>Error:</b> {errors.budgetNote}
                      </>
                    }
                  />
                  <span>
                    Enter any notes or details discussed during the meeting regarding the budget for this offer
                  </span>
                </div>
              </section>
              <section className={CLASSES.CONTENT_FOOTER}>
                <FlightButton theme="secondary" onClick={handleClickPrevious} label={`Previous step`} />
                <FlightButton theme="primary" onClick={handleSubmit} label={`Next`} disabled={!isValid} />
              </section>
            </main>
            {/* <ScrollToFormikErrorField /> */}
          </Form>
        );
      }}
    </Formik>
  );
}

export default OfferScheduleForm;
